:root {
  --Audiowide: "Audiowide", cursive;
  --RobotoCondensed: "Roboto Condensed", sans-serif;
  --RobotoSlab: "Roboto Slab", serif;
  --bodyBg: #1c2025;
  --tablesBg: #282c34;
  --itemsActiveBg: #4f5374;
  --overlay: rgba(28, 32, 37, 0.8);
  --white: #fff;
  --grey: #cacaca;
  --primary: #42a5f5;
  --secondary: #fb8c00;
  --error: #ff4444;
}
::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-width: 100vw;
  min-height: 100vh;
  font-family: var(--RobotoCondensed);
  background: var(--bodyBg);
  line-height: 1.3;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  .createOrderLayout {
    color: #606060;
  }
}
#root {
  min-width: 100vw;
  min-height: 100vh;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
td > svg {
  &:not(.main-status) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.MuiInput-underline::before {
  display: none;
}
.relative {
  position: relative;
}
.flexYCenter {
  display: flex;
  align-items: center;
}
.flexXYCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.darkTheme {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--tablesBg) inset !important;
  }
  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: var(--white) !important;
  }
}
